@import "../variables";

::-webkit-scrollbar {
    width: calc(8px * var(--widthmultiplier));
    border-radius: calc(4px * var(--widthmultiplier));
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba($color: #fd6125, $alpha: 0.2);
    border-radius: calc(4px * var(--widthmultiplier));
}

/* Handle */
::-webkit-scrollbar-thumb {
    // background: #cececf;
    background: #fd6125;
    border-radius: calc(4px * var(--widthmultiplier));
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    // background: #b30000;
    cursor: pointer;
}

.OrderTable {
    // border: 1px solid black;
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: var(--primaryColor);
    //border: 1px solid black;
    //display: flex;
    //margin: calc(10px * var(--heightmultiplier));
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    //border: 2px solid blue;

    //background-color: var(--primaryColor);
    border-radius: calc(16px * var(--heightmultiplier)) calc(0px * var(--heightmultiplier)) calc(16px * var(--heightmultiplier)) calc(16px * var(--heightmultiplier));

    //padding:  calc(10px * var(--heightmultiplier));
    &__filter {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: calc(50px * var(--heightmultiplier));
        width: 98%;

        &__title {
            //width: 7%;
            // background-color: #FD6125;
            color: var(--primaryTextColor);
            font-size: calc(24px * var(--heightmultiplier));
        }

        &__options {
            width: 30%;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            // border: 1px solid #FD6125;
            height: 80%;
            // padding-left: calc(20px * var(--heightmultiplier));

            &__item {
                width: 25%;
                color: var(--primaryTextColor);
                font-size: calc(18px * var(--heightmultiplier));
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px 10px;
                margin: 0px 10px;
                border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                background-color: white;
                border-color: #FD6125;
                color: black;

                &:hover {
                    background-color: rgba($color: #FD6125, $alpha: 1);
                    border-color: #FD6125;
                    color: white;
                }
                &:focus {
                    background-color: rgba($color: #FD6125, $alpha: 1);
                    border-color: #FD6125;
                    color: white;
                }

                &__open {
                    width: 25%;
                    color: var(--primaryTextColor);
                    font-size: calc(18px * var(--heightmultiplier));
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0px 10px;
                    margin: 0px 10px;
                    border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                    background-color: rgba($color: #F2D058, $alpha: 0.2);
                    // background-color: rgba(red, green, blue, alpha);
                    border-color: #F2D058;

                    &:hover {
                        background-color: rgba($color: #F2D058, $alpha: 1);
                        border-color: #F2D058;
                        color: white;
                    }
                    &:focus {
                        background-color: rgba($color: #F2D058, $alpha: 1);
                        border-color: #F2D058;
                        color: white;
                    }

                }

                &__delivered {
                    width: 25%;
                    color: var(--primaryTextColor);
                    font-size: calc(18px * var(--heightmultiplier));
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0px 10px;
                    margin: 0px 10px;
                    border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                    background-color: rgba($color: #6CCC54, $alpha: 0.2);
                    border-color: #6CCC54;
                    &:hover {
                        background-color: rgba($color: #6CCC54, $alpha: 1);
                        border-color: #6CCC54;
                        color: white;
                    }
                    &:focus {
                        background-color: rgba($color: #6CCC54, $alpha: 1);
                        border-color: #6CCC54;
                        color: white;
                    }

                }

                &__cancelled {
                    width: 25%;
                    color: var(--primaryTextColor);
                    font-size: calc(18px * var(--heightmultiplier));
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0px 10px;
                    margin: 0px 10px;
                    border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                    background-color: rgba($color: #F25858, $alpha: 0.2);
                    border-color: #F25858;

                    &:hover {
                        background-color: rgba($color: #F25858, $alpha: 1);
                        border-color: #F25858;
                        color: white;
                    }
                    &:focus {
                        background-color: rgba($color: #F25858, $alpha: 1);
                        border-color: #F25858;
                        color: white;
                    }

                }
            }

            &__selected-item {
                //width: 50%;
                color: white;
                font-size: calc(24px * var(--heightmultiplier));
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px 10px;
                margin: 0px 10px;
                border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                background-color: rgba($color: #FD6125, $alpha: 1);
                //border: 1px solid #6CCC54
                // &:hover {
                //     background-color: rgba($color: #FD6125, $alpha: 0.5);
                //     border-color: #FD6125;
                //     color: white;
                // }
            }
        }

        &__duration-title {
            // width: 10%;
            color: var(--primaryTextColor);
            font-size: calc(24px * var(--heightmultiplier));
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 10px;
            //border: 1px solid #6CCC54
        }

        &__duration-select {
            color: black;
            font-size: calc(20px * var(--heightmultiplier));
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 10px;
            margin: 0px 10px;
            border-color: #FD6125;
            //background-color: var(--primaryTextColor);
            //border: none;
            border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            // &__option{

            // }
        }
    }

    &__heading {
        width: 100%;
        //  height: fit-content;
        height: 10%;
        padding: calc(10px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
        font-size: calc(20px * var(--heightmultiplier));

        //border: 1px solid green;
        &__tabel {
            width: 100%;
            height: 100%;
            //border: 1px solid red;
            padding: calc(10px * var(--heightmultiplier));
            //margin-top: calc(10px * var(--heightmultiplier));
            background-color: rgb(124, 124, 124);
            color: white;
            // align-items: center;
            display: flex;
            align-items: center;
            //justify-content: space-between;
            border-radius: calc(8px * var(--heightmultiplier));

            &__item {
                width: 10%;
                // border: 1px solid #F2D058;
                // display:flex;
                // text-align: center;
                align-items: center;

                // justify-content: space-evenly;
                &__noItem {
                    width: 8%;
                    height: 100%;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: space-evenly;
                   // border: 1px solid red;
                    overflow: hidden;
                }

                &__customer {
                    width: 12%;
                    height: 100%;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: space-evenly;
                    //border: 1px solid red;
                    overflow: hidden;
                }

                &__weight {
                    width: 7%;
                    height: 100%;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: space-evenly;
                    //border: 1px solid red;
                    overflow: hidden;
                }

                &__quantity {
                    width: 7%;
                    height: 100%;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: space-evenly;
                    //border: 1px solid red;
                    overflow: hidden;
                }

                &__startLoc {
                    width: 10%;
                    height: 100%;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: space-evenly;
                    //border: 1px solid red;
                    overflow: hidden;
                }

                &__endLoc {
                    width: 12%;
                    height: 100%;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: space-evenly;
                    //border: 1px solid red;
                    overflow: hidden;
                }

                &__status {
                    width: 12%;
                    height: 100%;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: space-evenly;
                    //border: 1px solid red;
                    overflow: hidden;
                }

                &__orderDate {
                    width: 10%;
                    height: 100%;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: space-evenly;
                    //border: 1px solid red;
                    overflow: hidden;
                }

                &__deliveredD {
                    width: 10%;
                    height: 100%;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: space-evenly;
                    //border: 1px solid red;
                    overflow: hidden;
                }
            }

            &__itemStart {
                width: 7%;
                height: 100%;
                // text-align: left;
                text-align: center;

                &__orderNo {
                    width: 8%;
                    height: 100%;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: space-evenly;
                    //border: 1px solid red;
                    overflow: hidden;
                }
            }

            &__itemEnd {
                width: 7%;
                height: 100%;
                text-align: center;

                &__getDetails {
                    width: 10%;
                    height: 100%;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: space-evenly;
                    //border: 1px solid red;
                    overflow: hidden;
                }

            }
        }

        &__rows {
            overflow-y: scroll;
            // align-items: center;
            // display: flex;
            // align-items: center;
            width: 100%;
            height: 100%;
            padding: calc(2px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
            font-size: calc(20px * var(--heightmultiplier));

            //border: 1px solid black;
            // align-items: center;
            // display: flex;
            // align-items: center;
            &__itemRow {
                width: 100%;
                //width:fit-content;
                height: 12%;
                //height: fit-content;
                //border: 1px solid black;
                padding: calc(10px * var(--heightmultiplier));
                //margin-top: calc(5px * var(--heightmultiplier));
                background-color: var(--secondaryColor);
                font-size: calc(18px*var(--heightmultiplier));
                align-items: center;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                border-radius: calc(8px * var(--heightmultiplier));

                &:nth-of-type(even) {
                    background-color: var(--primaryColor);
                }


                &__item {
                    // width: 10%;
                    // height: 100%;
                    text-align: center;

                    // &__image {
                    //     width: auto;
                    //     height: 100%;
                    //     // border-radius: 50%;
                    // }

                    // &__updateButton {
                    //     width: 50%;
                    //     height: fit-content;
                    //     border: none;
                    //     // padding: calc(5px * var(--widthmultiplier))
                    //     // calc(5px * var(--heightmultiplier));
                    //     background-color: rgba($color: #03bd1c, $alpha: 0.1);
                    //     //opacity: 0.5;
                    //     color: var(--primaryTextColor);
                    //     border-radius: calc(10px* var(--heightmultiplier));

                    //     &:hover {
                    //         color: white;
                    //         background-color: rgba($color: #07920e, $alpha: 0.5);
                    //     }
                    // }
                    &__noItem {
                        width: 8%;
                        height: 100%;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: space-evenly;
                        //border: 1px solid red;
                        overflow: hidden;
                    }

                    &__customer {
                        width: 12%;
                        height: 100%;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: space-evenly;
                        //border: 1px solid red;
                        overflow: hidden;
                    }

                    &__weight {
                        width: 7%;
                        height: 100%;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: space-evenly;
                        //border: 1px solid red;
                        overflow: hidden;
                    }

                    &__quantity {
                        width: 7%;
                        height: 100%;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: space-evenly;
                        //border: 1px solid red;
                        overflow: hidden;
                    }

                    &__startLoc {
                        width: 10%;
                        height: 100%;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: space-evenly;
                        //border: 1px solid red;
                        overflow: hidden;
                    }

                    &__endLoc {
                        width: 12%;
                        height: 100%;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: space-evenly;
                        //border: 1px solid red;
                        overflow: hidden;
                    }

                    &__status {
                        // width: 12%;
                        // height: 100%;
                        // text-align: center;
                        // // align-items: center;
                        // border: 1px solid black;

                        &__colorCode8 {
                            width: 12%;
                            height: 100%;
                            display: flex;
                            text-align: center;
                            align-items: center;
                            justify-content: space-evenly;
                            //border: 1px solid red;
                            font-weight: 500;
                            overflow: hidden;
                            color: #6CCC54;
                        }

                        &__colorCode9 {
                            width: 12%;
                            height: 100%;
                            display: flex;
                            text-align: center;
                            align-items: center;
                            justify-content: space-evenly;
                            //border: 1px solid red;
                            font-weight: 500;
                            //overflow: hidden;
                            color: #F25858
                        }

                        &__colorCode1 {
                            width: 12%;
                            height: 100%;
                            // display: flex;
                            text-align: center;
                            align-items: center;
                            // justify-content: space-evenly;
                            //border: 1px solid red;
                            font-weight: 500;
                            overflow: hidden;
                            color: #F2D058;

                            &__updateBtn {
                                width: 80%;
                                height: 50%;
                                font-size: calc(16px * var(--heightmultiplier));
                                background-color: rgba($color: #F2D058, $alpha: 0.2);
                                border-color: #F2D058;
                                border-radius:  calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                                color: var(--primaryTextColor);
                                //margin: calc(10px * var(--heightmultiplier));
                                &:hover {
                                    background-color: rgba($color: #F2D058, $alpha: 1);
                                    border-color: #F2D058;
                                    color: black;
                                }

                            }
                        }

                        &__colorCode2 {
                            width: 12%;
                            height: 100%;
                           // display: flex;
                            text-align: center;
                            align-items: center;
                            //justify-content: space-evenly;
                            // border: 1px solid red;
                            font-weight: 500;
                            overflow: hidden;
                            color: #F2D058;

                            &__updateBtn {
                                width: 80%;
                                height: 50%;
                                font-size: calc(16px * var(--heightmultiplier));
                                background-color: rgba($color: #F2D058, $alpha: 0.2);
                                border-color: #F2D058;
                                border-radius:  calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                                color: var(--primaryTextColor);
                                //margin: calc(10px * var(--heightmultiplier));
                                &:hover {
                                    background-color: rgba($color: #F2D058, $alpha: 1);
                                    border-color: #F2D058;
                                    color: black;
                                }
                            }
                        }

                        &__colorCode3 {
                            width: 12%;
                            height: 100%;
                            display: flex;
                            text-align: center;
                            align-items: center;
                            justify-content: space-evenly;
                            //border: 1px solid red;
                            font-weight: 500;
                            overflow: hidden;
                            color: #F2D058;

                        }

                        &__colorCode4 {
                            width: 12%;
                            height: 100%;
                            display: flex;
                            text-align: center;
                            align-items: center;
                            justify-content: space-evenly;
                            //border: 1px solid red;
                            font-weight: 500;
                            overflow: hidden;
                            color: #F2D058
                        }

                        &__colorCode5 {
                            width: 12%;
                            height: 100%;
                            display: flex;
                            text-align: center;
                            align-items: center;
                            justify-content: space-evenly;
                            //border: 1px solid red;
                            font-weight: 500;
                            overflow: hidden;
                            color: #F2D058
                        }

                        &__colorCode6 {
                            width: 12%;
                            height: 100%;
                            display: flex;
                            text-align: center;
                            align-items: center;
                            justify-content: space-evenly;
                            //border: 1px solid red;
                            font-weight: 500;
                            overflow: hidden;
                            color: #F2D058
                        }

                        &__colorCode7 {
                            width: 12%;
                            height: 100%;
                            display: flex;
                            text-align: center;
                            align-items: center;
                            justify-content: space-around;
                            //border: 1px solid red;
                            font-weight: 500;
                            overflow: hidden;
                            color: #F2D058
                        }
                    }

                    &__orderDate {
                        width: 10%;
                        height: 100%;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: space-evenly;
                        //border: 1px solid red;
                        overflow: hidden;
                    }

                    &__deliveredD {
                        width: 10%;
                        height: 100%;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: space-evenly;
                        //border: 1px solid red;
                        overflow: hidden;
                    }
                }

                &__itemStart {
                    width: 7%;
                    height: 100%;
                    // text-align: left;
                    text-align: center;

                    &__orderNo {
                        width: 8%;
                        height: 100%;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: space-evenly;
                        //border: 1px solid red;
                        overflow: hidden;
                    }
                }

                &__itemEnd {
                    width: 7%;
                    height: 100%;
                    // height: calc(40px * var(--heightmultiplier));
                    // font-size: calc(40px * var(--heightmultiplier));
                    text-align: center;
                    box-sizing: border-box;
                    //border: 1px solid red;
                    &__getDetails {
                        width: 10%;
                        height: 100%;
                        box-sizing: border-box;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: space-evenly;
                        //border: 1px solid red;
                        overflow: hidden;
                        &__getBtn{
                            border: 2px solid #FD6125;
                            width: 75%;
                            height: 55%;
                            color: var(--primaryTextColor);
                            font-size: calc(18px * var(--heightmultiplier));
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            // padding: 0px 10px;
                            // margin: 0px 10px;
                            //font-size: calc(16px * var(--heightmultiplier));
                            //font-weight: 500;
                            border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                            background-color: rgba($color: #FD6125, $alpha: 0.2);
                            &:hover{
                                background-color: rgba($color: #FD6125, $alpha: 1);
                                color: white;
                            }
                        }
                    }
                    &__delete {
                        color: red;

                        // font-size: calc(24px * var(--heightmultiplier));
                        &:hover {
                            // font-size: calc(40px * var(--heightmultiplier));
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}