@import "../variables";

::-webkit-scrollbar {
    width: calc(8px * var(--widthmultiplier));
    border-radius: calc(4px * var(--widthmultiplier));
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e6e7e7;
    border-radius: calc(4px * var(--widthmultiplier));
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #cececf;
    border-radius: calc(4px * var(--widthmultiplier));
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    // background: #b30000; 
    cursor: pointer;
}

.OrdersListSubContent-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    
    // border: 1px solid black;

    &__title {
        box-sizing: border-box;
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(40px * var(--heightmultiplier));
        line-height: calc(54px * var(--heightmultiplier));
        color: var(--primaryTextColor);
        // margin-bottom: calc(48px * var(--heightmultiplier));

    }

    &__totalItems {
        box-sizing: border-box;
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 400;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        color: var(--primaryTextColor);
        margin-left: calc(3px * var(--heightmultiplier));

    }

    &__subContainer {
        box-sizing: border-box;
        width: 100%;
        height: calc(730px * var(--heightmultiplier));
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        //border: 1px solid black;

        &__listDown {
            box-sizing: border-box;
            height: fit-content;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            overflow-y: scroll;
           // border: 1px solid purple;
            //padding: calc(10px * var(--heightmultiplier));
            padding: calc(15px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
        }


    }
}

.OrdersListItems-container {
    height: calc(80px * var(--heightmultiplier));
    width: calc(1400px * var(--widthmultiplier));
    // background-color: var(--primaryColor);
    border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
    padding: calc(0px * var(--heightmultiplier)) calc(50px * var(--widthmultiplier));
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: calc(28px * var(--heightmultiplier));
    // border: 1px solid black;

    &__Sr-No {
        height: 100%;
        width: calc(60px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        padding: 0px;
        color: var(--primaryTextColor);
        display: flex;
        align-items: center;
        justify-content: center;
        // border: 1px solid black;
        // opacity: 0.4;
    }

    &__order-no {
        // height: calc(80px * var(--heightmultiplier));
        width: calc(300px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        // border: 1px solid black;

        color: var(--primaryTextColor); // opacity: 0.4;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__quantity {
        // height: calc(80px * var(--heightmultiplier));
        width: calc(200px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        display: flex;
        align-items: center;
        justify-content: center;

        color: var(--primaryTextColor) // opacity: 0.4;
    }

    &__totalWeight {
        // height: calc(80px * var(--heightmultiplier));
        width: calc(150px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        text-align: center;

        color: var(--primaryTextColor); // opacity: 0.4;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__total-quantity {
        width: calc(150px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        text-align: center;

        color: var(--primaryTextColor);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__total-price {
        width: calc(150px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        text-align: center;

        color: var(--primaryTextColor);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__status {
        width: calc(210px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        text-align: center;
        color: var(--primaryTextColor);
        display: flex;
        align-items: center;
        justify-content: center;
        // border: 1px solid black;
    }
}