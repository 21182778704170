@import "./variables";

@font-face {
    font-family: 'General Sans';
    src: url('../fonts/fonts/GeneralSans-Variable.woff2') format('woff2'),
        url('../fonts/fonts/GeneralSans-Variable.woff') format('woff'),
        url('../fonts/fonts/GeneralSans-Variable.ttf') format('truetype');
    font-weight: 200 700;
    font-display: swap;
    font-style: normal;
}

html {
    font-size: calc(16px * var(--heightmultiplier));
    --bs-blue: #FD6125;
}

body {
    font-family: "General Sans";
    font-size: calc(16px * var(--heightmultiplier));

}

.home-page-container {
    position: relative;
    // height: 100%;
    height: calc(1080px * var(--heightmultiplier));
    // width: 100%;
    width: calc(1920px * var(--widthmultiplier));
    // overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background-color: aquamarine;
    box-sizing: border-box;
    background-color: var(--secondaryColor);
    color: var(--primaryTextColor);
    font-size: calc(16px * var(--heightmultiplier));
    font-family: "General Sans";
}

.home-sidebar {
    // flex: 1;
    background-color: var(--primaryColor);
    // border: 2px solid #FD6125;
    // border-left-color: white;
    box-sizing: border-box;
    width: calc(341px * var(--widthmultiplier));
    height: 100%;
    border-top-right-radius: calc(20px * var(--widthmultiplier));
    border-bottom-right-radius: calc(20px * var(--widthmultiplier));
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;


    &__logodiv {
        height: 8%;
        width: 100%;
        // background: #f0f0f0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding: 5px 20px;
        // padding-right: 50px;

        &__logoimg {
            margin-left: 2em;
            height: 2.4em;
            width: 10em;
        }

        &__back {
            margin-right: 50px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__logodiv-border {
        height: calc(2px * var(--heightmultiplier));
        width: calc(301px * var(--widthmultiplier));
        background-color: var(--secondaryColor);
    }

    &__Categories-container {
        width: calc(273px * var(--widthmultiplier));
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        &__Title {
            // width: 100%;
            font-size: 2em;

            &:hover {
                cursor: pointer;
            }
        }
    }

}

.home-main-map {
    height: 100%;
    width: 82.24%;
    margin-right: 0.0428%;
    // border: 2px solid black;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &__topInfobar {
        position: relative;
        height: 15.65%;
        width: 92.4%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // border: 1px solid black;
        // padding-top: calc(45px * var(--heightmultiplier));
        // border: 1px solid black;

        &__phc-logo {
            // height: calc(45px * var(--heightmultiplier));
            height: 95%;
            // width: calc(45px * var(--heightmultiplier));
            width: auto;
            // border: 1px solid #FD6125;
            // color: var(--primaryTextColor);
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // font-size: 50px;
        }

        &__search-container {
            // height: calc(45px * var(--heightmultiplier));
            height: 100%;
            margin-left: calc(13px * var(--widthmultiplier));
            // border: 1px solid black;
            color: var(--primaryTextColor);

            &__search {
                font-size: 1.1em;
                color: var(--primaryTextColor);
            }


        }

        &__user {
            // height: 100%;
            width: calc(200px * var(--widthmultiplier));
            position: absolute;
            top: calc(45px * var(--heightmultiplier));
            right: calc(0px * var(--widthmultiplier));
            margin-right: 0px;
            display: flex;
            align-items: center;
            justify-self: space-between;
            // border: 1px solid black;
            box-sizing: border-box;

            &__bagStr {
                width: 50%;
                //border: 1px solid red;
                box-sizing: border-box;
                font-family: 'General Sans';
                font-style: normal;
                font-weight: 500;
                font-size: calc(16px * var(--heightmultiplier));
                line-height: calc(20px * var(--heightmultiplier));

                color: var(--primaryTextColor);
            }
            &__bagImg{
                width: 50%;
                //border: 1px solid black;
                //height: calc(20px * var(--heightmultiplier));
                position: relative;
                &__productCount{
                    box-sizing: content-box;
                    position: absolute;
                    right:calc(-5px * var(--heightmultiplier));
                    top:calc(-10px * var(--heightmultiplier));
                    font-size: calc(14px * var(--heightmultiplier));
                    font-weight: 600;
                    z-index: 10;
                    background-color: #FD6125;
                    color: var(--primaryColor);
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    width: calc(20px * var(--heightmultiplier));
                    height: calc(20px * var(--heightmultiplier));
                    border-radius: 50%;
                    border: calc(2px * var(--heightmultiplier)) solid var(--primaryColor);
                }

                &__img {
                    box-sizing: border-box;
                    height: calc(24px * var(--heightmultiplier));
                    width: calc(24px * var(--widthmultiplier));
                    // padding-left: calc(6px * var(--widthmultiplier));
                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            &__img {
                box-sizing: border-box;
                height: calc(8px * var(--heightmultiplier));
                width: calc(14px * var(--widthmultiplier));
                padding-left: calc(6px * var(--widthmultiplier));
                

                &:hover {
                    cursor: pointer;
                }
            }

            &__proImg {
                box-sizing: border-box;
                height: 3em;
                width: 3em;
                // min-width: 50px;
                margin-left: 1em;
                box-sizing: border-box;
                border-radius: 50%;
                background-color: #FD6125;
                color: var(--primaryTextColor);
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    // background-color: rgba(253,97,37,0.5);
                    cursor: pointer;
                }
            }

            &__name {
                box-sizing: border-box;
                font-size: 1.5em;
            }
        }




    }

    &__content {
        position: relative;
        top: 0px;
        left: 0px;
        height: 77.36%;
        width: 92.4%;
        // border-radius: 30px;
        overflow: hidden;
        // border: 1px solid black;


    }

    &__footer-container {
        position: relative;
        height: 7%;
        width: 91.6%;
        display: flex;
        align-items: center;
        justify-content: center;

        &__copyright {
            width: 32.9%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__company-logo {
                width: 11.2%;
                height: 5.13;
            }

            &__title {
                color: #878B8F;
                font-size: 1em;
            }
        }


    }

    &__search-container {
        font-size: 20px;
        margin-left: 20px;
        display: flex;
        align-items: center;

        &__img {
            height: 100%;
            width: auto;
            margin-right: 0.2em;
        }

        &__search {
            outline: none;
            border: none;
            background-color: inherit;
            font-size: 1.25em;
        }
    }
}

.trapezoid-container {
    position: absolute;
    top: 42%;
    right: 43.5%;
    height: 100px;
    width: 240px;
    display: flex;
    align-items: center;
    overflow: hidden;
    z-index: 3;
    // border: 1px solid black;
    transition: all 0.1s linear;
    // transform: rotate(-90deg);
    // &:hover{
    //     transform: rotate(180deg);
    // }

    &__img-container {
        position: relative;
        // border: 1px solid black;
        height: 50%;
        width: 30%;

        &__img {
            position: absolute;
            right: 30px;
            top: -3.5px;
            transform: rotate(-90deg);

            &:hover {
                cursor: pointer;
            }
        }
    }
}

#trapezoid {
    // position: absolute;
    // top:50vh;
    // right: 50vw;
    margin-top: 0vw;
    width: 100px;
    height: 30px;

    background-image: linear-gradient(to right,
            rgba(233, 69, 14, 0) 30%,
            rgba(233, 69, 14, 0.6) 50%,
            rgba(233, 69, 14, 1) 100%);
    transform: perspective(20px) rotateY(45deg);
    //   border: 2px solid black;
}

.m__home-page-container {
    position: relative;

    // height: 100%;
    height: calc(1080px * var(--heightmultiplier));
    // width: 100%;
    width: calc(1920px * var(--widthmultiplier));
    // overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    // background-color: aquamarine;
    box-sizing: border-box;
    background-color: var(--secondaryColor);
    color: var(--primaryTextColor);
    font-size: calc(16px * var(--heightmultiplier));

    &__topbar{
        width: 100%;
        height: calc(80px * var(--heightmultiplier));
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__logoimg{
            height: calc(50px * var(--heightmultiplier));
            
        }
    }
}