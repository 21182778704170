.DeleteFormContainer {
  width: 100%;
  height: 100%;
  //border: 1px solid black;
  display: block;
  text-align: center;
  //background-color: white;
  //padding: calc(20px * var(--heightmultiplier));
  //font-size: calc(14px * var(--heightmultiplier));
  &__DeleteHeading {
    width: fit-content;
    height: fit-content;
    //width: 70%;
    box-sizing: border-box;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: calc(40px * var(--heightmultiplier));
    line-height: calc(54px * var(--heightmultiplier));
    color: var(--primaryTextColor);
    margin: calc(2px * var(--widthmultiplier)) calc(5px * var(--heightmultiplier));
    //border: 2px solid green;
    //background-color: var(--secondaryColor);
    //padding: calc(0px * var(--widthmultiplier)) calc(20px * var(--heightmultiplier));
  }
  &__container {
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //border: 2px solid rgb(59, 11, 190);
    &__imageSide{
        width: 50%;
        height: 100%;
    }
    &__delete-img-container {
      width: 100%;
      height:100%;
      //border: 2px solid red;
      //height: calc(110px * var(--heightmultiplier));
      //padding: calc(5px * var(--heightmultiplier));
      color: var(--primaryTextColor);
      display: flex;
    //   margin: calc(30px * var(--heightmultiplier))
    //     calc(0px * var(--widthmultiplier));
      margin-bottom: calc(0px * var(--heightmultiplier));
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      &__lable {
        width: fit-content;
        //height: calc(40px * var(--heightmultiplier));
        height: fit-content;
        //width: 70%;
        box-sizing: border-box;
        font-family: "General Sans";
        font-style: normal;
        font-weight: 500;
        font-size: calc(24px * var(--heightmultiplier));
        line-height: calc(54px * var(--heightmultiplier));
        color: var(--primaryTextColor);
        
      }
      &__image {
        height: calc(200px * var(--heightmultiplier));
        width: calc(200px * var(--heightmultiplier));
        margin: calc(20px * var(--heightmultiplier));
      }
    }
    &__contentSide{
        width: 70%;
        height: 100%;
        //border: 1px solid brown;
        //margin: calc(20px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
        padding-top: calc(30px * var(--heightmultiplier));
    }
    &__content {
        width: 100%;
        height: 100%;
        //border: 1px solid orange;
        &__subContainer {
          display: flex;
          align-items: flex-start;
          //justify-content: space-between;
        }
        &__lable {
          width: calc(120px * var(--heightmultiplier));
          height: calc(40px * var(--heightmultiplier));
          //height: fit-content;
          //width: 70%;
          box-sizing: border-box;
          font-family: "General Sans";
          font-style: normal;
          font-weight: 500;
          font-size: calc(20px * var(--heightmultiplier));
          line-height: calc(32px * var(--heightmultiplier));
          //border: 1px solid blue;
          justify-items: flex-start;
          color: var(--primaryTextColor);
          margin: calc(2px * var(--heightmultiplier))
            calc(5px * var(--widthmultiplier));
        }
        &__colon{
        width: fit-content;
        height: calc(40px * var(--heightmultiplier));
          //height: fit-content;
          //width: 70%;
          box-sizing: border-box;
          font-family: "General Sans";
          font-style: normal;
          font-weight: 500;
          font-size: calc(20px * var(--heightmultiplier));
          line-height: calc(32px * var(--heightmultiplier));
          //border: 1px solid blue;
          color: var(--primaryTextColor);
          margin: calc(2px * var(--heightmultiplier))
          calc(5px * var(--widthmultiplier));
        }
        &__itemDataFiled {
          width: fit-content;
          //height: calc(40px * var(--heightmultiplier));
          height:fit-content;
          //width: 70%;
          box-sizing: border-box;
          font-family: "General Sans";
          font-style: normal;
          font-weight: 500;
          font-size: calc(20px * var(--heightmultiplier));
          line-height: calc(32px * var(--heightmultiplier));
          color: var(--primaryTextColor);
          //border: 1px solid green;
          margin: calc(2px * var(--heightmultiplier))
            calc(0px * var(--widthmultiplier));
        }
      }
  }
  &__Options{
    //border: 1px solid pink;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: calc(10px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
  }
//   &__custom-alert-box-img {
//     height: 29.17%;
//   }
}
