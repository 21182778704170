.OrderDetailContainer {
  width: 100%;
  height: 100%;
  //border: 1px solid black;
  display: block;

  // align-items: center;
  // justify-content: space-between;
  //background-color: white;
  //padding: calc(20px * var(--heightmultiplier));
  //font-size: calc(14px * var(--heightmultiplier));
  &__heading {
    width: 100%;
    height: 8%;
    //width: 70%;
    box-sizing: border-box;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: center;
   // border: 2px solid orangered;
    //justify-content: space-between;
    font-size: calc(28px * var(--heightmultiplier));
    //line-height: calc(54px * var(--heightmultiplier));
    color: var(--primaryTextColor);
    margin: calc(2px * var(--widthmultiplier)) calc(5px * var(--heightmultiplier));
    &__h1{
      width: 98%;
      height: 100%;
      //border: 1px solid green;
    }
    &__cancel{
      width: 2%;
      height: 100%;
      //border: 1px solid green;
      &:hover{
        cursor: pointer;
      }
    }
  }

  &__Container {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border: 1px solid red
  }

  &__detailsContainer {
    width: 50%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    justify-content: flex-start;
    //border: 2px solid rgb(59, 11, 190);

    &__Title {
      width: 100%;
      height: 10%;
      box-sizing: border-box;
      font-family: 'General Sans';
      font-style: normal;
      font-weight: 500;
      font-size: calc(24px * var(--heightmultiplier));
      // line-height: calc(54px * var(--heightmultiplier));
      color: var(--primaryTextColor);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      //border: 1px solid red;
      // margin-bottom: calc(48px * var(--heightmultiplier))
    }

    &__TotalItem {
      width: 100%;
      height: 10%;
      box-sizing: border-box;
      font-family: 'General Sans';
      font-style: normal;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      font-size: calc(20px * var(--heightmultiplier));
      // line-height: calc(27px * var(--heightmultiplier));
      color: var(--primaryTextColor);
      // margin-left: calc(3px * var(--heightmultiplier));
    }

    &__sub-Container {
      width: 100%;
      height: 70%;
      //padding: calc(5px * var(--heightmultiplier));
      color: var(--primaryTextColor);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // border: 2px solid rgb(3, 2, 9);

      &__title {
        width: 100%;
        height: 15%;
        // border: 1px solid yellow;
        display: flex;
        // flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: space-between;
        background-color: rgb(206, 206, 207);
        color: black;
        border-radius: calc(10px * var(--heightmultiplier));

        &__Sr-No {
          height: 100%;
          width: 10%;
          font-family: 'General Sans';
          display: flex;
          align-items: center;
          justify-content: center;
          font-style: normal;
          font-weight: 500;
          font-size: calc(18px * var(--heightmultiplier));
          //color: var(--primaryTextColor);
          //border: 1px solid magenta
        }

        &__name {
          height: 100%;
          width: 40%;
          font-family: 'General Sans';
          font-style: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: calc(18px * var(--heightmultiplier));
          // color: var(--primaryTextColor);
          // border: 1px solid magenta
        }

        &__weight {
          height: 100%;
          width: 10%;
          font-family: 'General Sans';
          font-style: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: calc(18px * var(--heightmultiplier));
          // color: var(--primaryTextColor);
          // border: 1px solid magenta
        }

        &__Volumetricweight {
          height: 100%;
          width: 14%;
          font-family: 'General Sans';
          font-style: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: calc(18px * var(--heightmultiplier));
          //color: var(--primaryTextColor);
          // border: 1px solid magenta
        }

        &__quantity {
          height: 100%;
          width: 10%;
          font-family: 'General Sans';
          font-style: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: calc(18px * var(--heightmultiplier));
          //color: var(--primaryTextColor);
          //border: 1px solid magenta
        }

        &__totalWeight {
          height: 100%;
          width: 15%;
          font-family: 'General Sans';
          font-style: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: calc(18px * var(--heightmultiplier));
          //color: var(--primaryTextColor);
          //border: 1px solid magenta
        }
      }

      &__dataContainer {
        height: 70%;
        width: 100%;
        //border: 2px solid red;
        padding: calc(10px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
        overflow-y: scroll;
      }
      &__cancleReason {
        height: 70%;
        width: 100%;
        //border: 2px solid red;
        padding: calc(10px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
        overflow-y: scroll;
      }

      &__listDown {
        width: 100%;
        height: 20%;
        // border: 1px solid yellow;
        display: flex;
        // flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: space-between;
        border-radius: calc(10px * var(--heightmultiplier));
        background-color: var(--secondaryColor);

        &:nth-of-type(even) {
          background-color: var(--primaryColor);
        }

        &__Sr-No {
          height: 100%;
          width: 10%;
          font-family: 'General Sans';
          display: flex;
          align-items: center;
          justify-content: center;
          font-style: normal;
          font-weight: 500;
          font-size: calc(16px * var(--heightmultiplier));
          color: var(--primaryTextColor);
          //border: 1px solid magenta
        }

        &__name {
          height: 100%;
          width: 40%;
          font-family: 'General Sans';
          font-style: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: calc(16px * var(--heightmultiplier));
          color: var(--primaryTextColor);
          //border: 1px solid magenta
        }

        // &__unit-price{
        //   height: 100%;
        //   width: 12%;
        //   font-family: 'General Sans';
        //   font-style: normal;
        //   display: flex;
        //     align-items: center;
        //     justify-content: center;
        //   font-weight: 500;
        //   font-size: calc(20px * var(--heightmultiplier));
        //   color: var(--primaryTextColor);
        //     border: 1px solid magenta

        // }
        &__weight {
          height: 100%;
          width: 10%;
          font-family: 'General Sans';
          font-style: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: calc(16px * var(--heightmultiplier));
          color: var(--primaryTextColor);
          //border: 1px solid magenta
        }

        &__Volumetricweight {
          height: 100%;
          width: 14%;
          font-family: 'General Sans';
          font-style: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: calc(16px * var(--heightmultiplier));
          color: var(--primaryTextColor);
          //border: 1px solid magenta
        }

        &__quantity {
          height: 100%;
          width: 10%;
          font-family: 'General Sans';
          font-style: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: calc(16px * var(--heightmultiplier));
          color: var(--primaryTextColor);
          //border: 1px solid magenta
        }

        &__totalWeight {
          height: 100%;
          width: 15%;
          font-family: 'General Sans';
          font-style: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: calc(16px * var(--heightmultiplier));
          color: var(--primaryTextColor);
          //border: 1px solid magenta
        }

        // &__total-price{
        //   height: 100%;
        //   width: 12%;
        //   font-family: 'General Sans';
        //   font-style: normal;
        //   display: flex;
        //     align-items: center;
        //     justify-content: center;
        //   font-weight: 500;
        //   font-size: calc(20px * var(--heightmultiplier));
        //   color: var(--primaryTextColor);
        //     border: 1px solid magenta
        // }
      }

      &__totalWeight {
        width: 100%;
        height: 20%;
        //border: 1px solid yellow;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        background-color: var(--primaryColor);

        &__title {
          height: 100%;
          width: 20%;
          font-family: 'General Sans';
          display: flex;
          align-items: center;
          justify-content: center;
          font-style: normal;
          font-weight: 500;
          font-size: calc(18px * var(--heightmultiplier));
          color: var(--primaryTextColor);
          //border: 1px solid magenta
        }

        &__value {
          height: 100%;
          width: 10%;
          font-family: 'General Sans';
          font-style: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: calc(18px * var(--heightmultiplier));
          color: var(--primaryTextColor);
          //border: 1px solid magenta
        }
      }
    }
  }

  &__Right {
    width: 49%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    justify-content: flex-start;
    //border: 2px solid rgb(59, 11, 190);
  }

  &__dataContainer {
    width: 100%;
    height: 10%;
    box-sizing: border-box;
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 500;
    font-size: calc(24px * var(--heightmultiplier));
    // line-height: calc(54px * var(--heightmultiplier));
    color: var(--primaryTextColor);
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    // border: 2px solid rgb(59, 11, 190);
    padding: calc(2px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
  }

  &__content {
    width: 100%;
    height: 70%;
    // line-height: calc(54px * var(--heightmultiplier));
    color: var(--primaryTextColor);
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    // border: 2px solid rgb(59, 11, 190);
    padding: calc(2px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
    &__customerDataContainer{
      position: relative;
      width: 80%;
      height: fit-content;
     // border: 1px solid red;
    //   display: flex;
    //  // flex-direction: row;
    //   text-align: center;
    //   align-items: center;
    //   justify-content: center;
      border-radius: calc(10px * var(--heightmultiplier));
      color: var(--primaryTextColor);
      border: 1px solid var(--primaryTextColor);
      &__label {
          position: absolute;
          width: fit-content;
          margin-left: calc(10px * var(--heightmultiplier));
          padding: calc(0px * var(--heightmultiplier))calc(5px * var(--widthmultiplier));
          height: fit-content;
          //border: 1px solid blue;
          font-size: calc(16px * var(--heightmultiplier));
          font-weight: 500;
          display: flex;
          text-align: flex-start;
          align-items: center;
          top: calc(-13px * var(--heightmultiplier));
          left: 0px;
          background-color: var(--primaryColor);
          color: var(--primaryTextColor)
      }
      &__value{
          width: 100%;
          height: 50%;
          // display: flex;
          // flex-direction: row;
          // text-align: center;
          // align-items:center;
          // justify-content: space-around;
         // padding-top: calc(20px * var(--heightmultiplier));
          margin-top: calc(5px * var(--heightmultiplier));
          //margin-left: calc(-100px * var(--heightmultiplier));
          &__UserDataContainer {
              width: fit-content;
              height: fit-content;
              display: flex;
              text-align: center;
              align-items: center;
              justify-content: space-between;
              box-sizing: border-box;
              font-family: 'General Sans';
              font-style: normal;
              font-weight: 400;
              font-size: calc(16px * var(--heightmultiplier));
              //line-height: calc(27px * var(--heightmultiplier));
              color: var(--primaryTextColor);
              margin-top: calc(2px * var(--heightmultiplier));
              margin-right: calc(35px * var(--heightmultiplier));
              padding-right: 0px;
              padding-left: calc(15px * var(--widthmultiplier));
  
              //border: 1px solid orchid;
              &__label {
                  font-size: calc(16px * var(--heightmultiplier));
                  font-weight: 500;
              }
          }
      }
     
  }

    &__user {
      width: 100%;
      height: 10%;
      color: var(--primaryTextColor);
      display: flex;
      align-items: center;
      justify-content: flex-start;

      // border: 2px solid rgb(11, 190, 17);
      //padding: calc(2px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
      &__label {
        width: 40%;
        height: fit-content;
        text-align: left;
        font-size: calc(20px*var(--heightmultiplier));
        font-weight: 500;
        // border: 1px solid rgb(128, 0, 0);
      }

      &__colon {
        width: 2%;
        min-height: fit-content;
        font-size: calc(20px*var(--heightmultiplier));
        font-weight: 500;
        //border: 1px solid rgb(13, 0, 128);
      }

      &__value {
        width: 58%;
        min-height: fit-content;
        text-align: left;
        padding-left: calc(8px * var(--heightmultiplier));
        font-size: calc(18px*var(--heightmultiplier));
        //border: 1px solid green;
      }
    }

    &__type {
      width: 100%;
      height: 10%;
      color: var(--primaryTextColor);
      display: flex;
      text-align: center;
      justify-content: flex-start;
      // border: 2px solid rgb(11, 190, 17);
      &__label {
        width: 40%;
        height: fit-content;
        text-align: left;
        font-size: calc(20px*var(--heightmultiplier));
        font-weight: 500;
        // border: 1px solid rgb(128, 0, 0);
      }

      &__colon {
        width: 2%;
        min-height: fit-content;
        font-size: calc(20px*var(--heightmultiplier));
        font-weight: 500;
        //border: 1px solid rgb(13, 0, 128);
      }

      &__value {
        width: 58%;
        min-height: fit-content;
        text-align: left;
        padding-left: calc(8px * var(--heightmultiplier));
        font-size: calc(18px*var(--heightmultiplier));
        //border: 1px solid green;
      }
    }

    &__startLoc {
      width: 100%;
      height: 10%;
      color: var(--primaryTextColor);
      display: flex;
      justify-content: flex-start;
      // border: 2px solid rgb(11, 190, 17);
      &__label {
        width: 40%;
        height: fit-content;
        text-align: left;
        font-size: calc(20px*var(--heightmultiplier));
        font-weight: 500;
        // border: 1px solid rgb(128, 0, 0);
      }

      &__colon {
        width: 2%;
        min-height: fit-content;
        font-size: calc(20px*var(--heightmultiplier));
        font-weight: 500;
        //border: 1px solid rgb(13, 0, 128);
      }

      &__value {
        width: 58%;
        min-height: fit-content;
        text-align: left;
        padding-left: calc(8px * var(--heightmultiplier));
        font-size: calc(18px*var(--heightmultiplier));
        //border: 1px solid green;
      }
    }

    &__endLoc {
      width: 100%;
      height: 10%;
      color: var(--primaryTextColor);
      display: flex;
      justify-content: flex-start;
      //border: 2px solid rgb(11, 190, 17);
      &__label {
        width: 40%;
        height: fit-content;
        text-align: left;
        font-size: calc(20px*var(--heightmultiplier));
        font-weight: 500;
        // border: 1px solid rgb(128, 0, 0);
      }

      &__colon {
        width: 2%;
        min-height: fit-content;
        font-size: calc(20px*var(--heightmultiplier));
        font-weight: 500;
        //border: 1px solid rgb(13, 0, 128);
      }

      &__value {
        width: 58%;
        min-height: fit-content;
        text-align: left;
        padding-left: calc(8px * var(--heightmultiplier));
        font-size: calc(18px*var(--heightmultiplier));
        //border: 1px solid green;
      }
    }

    &__date {
      width: 100%;
      height: 10%;
      color: var(--primaryTextColor);
      display: flex;
      justify-content: flex-start;
      //border: 2px solid rgb(11, 190, 17);
      font-size: calc(18px*var(--heightmultiplier));

      //padding: calc(2px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
      &__label {
        width: 40%;
        height: fit-content;
        text-align: left;
        font-size: calc(20px*var(--heightmultiplier));
        font-weight: 500;
        // border: 1px solid rgb(128, 0, 0);
      }

      &__colon {
        width: 2%;
        min-height: fit-content;
        font-size: calc(20px*var(--heightmultiplier));
        font-weight: 500;
        //border: 1px solid rgb(13, 0, 128);
      }

      &__value {
        width: 58%;
        min-height: fit-content;
        text-align: left;
        padding-left: calc(8px * var(--heightmultiplier));
        font-size: calc(18px*var(--heightmultiplier));
        //border: 1px solid green;
      }
    }
  }
  &__button{
    width: 100%;
    height: 10%;
    //border: 1px solid #F25858;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &__inputFiled {
      width: 96%;
      height: fit-content;
      border: 1px solid var(--primaryTextColor);
      border-radius: calc(10px * var(--heightmultiplier));
      margin: calc(10px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));
      background-color: var(--primaryColor);
      // border: 1px solid red;
      // padding: calc(10px * var(--heightmultiplier))
      // calc(20px * var(--widthmultiplier));
      //overflow-y: auto;
      //overflow-x: hidden;
      // position: relative;
      max-height: 40%;

      &__label {
        width: fit-content;
        margin-left: calc(10px * var(--heightmultiplier));
        padding: calc(0px * var(--heightmultiplier)) calc(5px * var(--widthmultiplier));
        height: 2%;
        //border: 1px solid blue;
        font-size: calc(16px * var(--heightmultiplier));
        font-weight: 600;
        display: flex;
        text-align: flex-start;
        align-items: center;
        margin-top: calc(-12px * var(--heightmultiplier));
        background-color: var(--primaryColor);
        color: var(--primaryTextColor) // position: absolute;
          // left: 0px;
          // top: 0px;
      }

      &__value {
        width: 100%;
        height: fit-content;
        max-height: 40%;
        //border: 1px solid blue;
        font-size: calc(16px * var(--heightmultiplier));
        display: flex;
        text-align: flex-start;
        align-items: center;
        background-color: transparent;
        border-radius: calc(10px * var(--heightmultiplier));
        padding: calc(5px * var(--heightmultiplier));


        &__input {
          width: 100%;
          height: 100%;
          outline: none;
          border: none;
          color: var(--primaryTextColor);
          background-color: transparent;
          overflow-y: auto;
          overflow-x: hidden;
          max-height: 40%;
        }
      }
    }
    &__orderCancel{
      width: 25%;
      height: 100%;
      border: 2px solid red;
      background-color: rgba($color: red, $alpha: 0.2);
      font-size: calc(18px*var(--heightmultiplier));
      font-weight: 500;
      border-radius: calc(5px * var(--heightmultiplier));
      color: red;
      &:hover{
        cursor: pointer;
        background-color: rgba($color: red, $alpha: 1);
        color: white;
      }
    }
  }

  &__Options {
    //border: 1px solid pink;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: calc(10px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
  }
}
