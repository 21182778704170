@import "../variables";

::-webkit-scrollbar {
    width: calc(8px * var(--widthmultiplier));
    border-radius: calc(4px * var(--widthmultiplier));
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e6e7e7;
    border-radius: calc(4px * var(--widthmultiplier));
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #cececf;
    border-radius: calc(4px * var(--widthmultiplier));
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    // background: #b30000; 
    cursor: pointer;
}

.SpecificOrderSubContent-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    // border: 1px solid black;

    &__title {
        width: fit-content;
        height: 100%;
        box-sizing: border-box;
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(40px * var(--heightmultiplier));
        line-height: calc(54px * var(--heightmultiplier));
        color: var(--primaryTextColor);
        display: flex;
        align-items: center;
        justify-content: space-between;

        // margin-bottom: calc(48px * var(--heightmultiplier));
        //border: 1px solid red;


    }

    &__firstHead {
        position: relative;
        width: 83.5%;
        height: 100%;
        //border: 1px solid red;
        display: flex;
        text-align: center;
        align-items: center;

        //justify-content: space-between;
        &__refresh {
            position: absolute;
            top: 0px;
            right: 0px;
            height: calc(45px * var(--heightmultiplier));
            width: auto;
            transition: all 0.3s linear;
            margin-right: calc(50px * var(--widthmultiplier));

            //border: 1px solid orchid;
            &:hover {
                cursor: pointer;
                transform: rotate(90deg);
            }
        }
    }

    &__customerDataContainer {
        position: relative;
        width: 70%;
        height: 80%;
        // border: 1px solid red;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        border-radius: calc(10px * var(--heightmultiplier));
        color: var(--primaryTextColor);
        border: 1px solid var(--primaryTextColor);

        &__label {
            position: absolute;
            width: fit-content;
            margin-left: calc(10px * var(--heightmultiplier));
            padding: calc(0px * var(--heightmultiplier))calc(5px * var(--widthmultiplier));
            height: fit-content;
            //border: 1px solid blue;
            font-size: calc(16px * var(--heightmultiplier));
            font-weight: 500;
            display: flex;
            text-align: flex-start;
            align-items: center;
            top: calc(-13px * var(--heightmultiplier));
            left: 0px;
            background-color: var(--secondaryColor);
            color: var(--primaryTextColor)
        }

        &__value {
            width: 100%;
            height: 50%;
            display: flex;
            text-align: center;
            align-items: flex-end;
            justify-content: space-around;
            //border: 1px solid red;
            justify-content: space-between;
            // padding-top: calc(20px * var(--heightmultiplier));
            margin-top: calc(10px * var(--heightmultiplier));

            //margin-left: calc(-100px * var(--heightmultiplier));
            &__UserDataContainer {
                width: fit-content;
                height: fit-content;
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: space-between;
                box-sizing: border-box;
                font-family: 'General Sans';
                font-style: normal;
                font-weight: 400;
                font-size: calc(16px * var(--heightmultiplier));
                //line-height: calc(27px * var(--heightmultiplier));
                color: var(--primaryTextColor);
                margin-top: calc(20px * var(--heightmultiplier));
                margin-right: calc(35px * var(--heightmultiplier));
                padding-right: 0px;
                padding-left: calc(15px * var(--widthmultiplier));

                //border: 1px solid orchid;
                &__label {
                    font-size: calc(16px * var(--heightmultiplier));
                    font-weight: 500;
                }
            }
        }

    }

    &__ItemContainer {
        width: 100%;
        //border: 1px solid yellowgreen;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: space-between;
    }

    &__totalItems {
        width: fit-content;
        height: 100%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 400;
        font-size: calc(20px * var(--heightmultiplier));
        //line-height: calc(27px * var(--heightmultiplier));
        color: var(--primaryTextColor);
        margin-left: calc(3px * var(--heightmultiplier));
        padding-left: 0px;
        padding-right: calc(20px * var(--widthmultiplier));

        // border: 1px solid orchid;
        &__label {
            font-weight: 500;
        }
    }

    &__placedDate {
        width: fit-content;
        height: 100%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 400;
        font-size: calc(20px * var(--heightmultiplier));
        //line-height: calc(27px * var(--heightmultiplier));
        color: var(--primaryTextColor);
        padding: calc(0px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));

        // border: 1px solid orchid;
        &__label {
            font-weight: 500;
        }
    }

    &__statusBar {
        width: 100%;
        // height: 100%;
        // border: 1px solid red;
        padding: calc(10px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));

        &__canceled {
            width: 100%;
            // height: 100%;
            // border: 1px solid red;
            font-size: calc(36px * var(--heightmultiplier));
            font-weight: 600;
            font-family: 'General Sans';
            color: red;
            // display: flex;
            // align-items: center;
            // text-align: center;
            // justify-content: space-around;
        }

        &__canceledReason {
            width: 99%;
            height: 20%;
            //border: 1px solid blue;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: space-between;
            font-size: calc(16px * var(--heightmultiplier));
            margin-left: calc(5px * var(--heightmultiplier));
            font-family: 'General Sans';
            color: var(--primaryTextColor);
            font-weight: 600;

            &__value {
                width: 88%;
                height: 200%;
                // border: 1px solid blue;
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: space-between;
                font-size: calc(16px * var(--heightmultiplier));
                margin-left: calc(5px * var(--heightmultiplier));
                font-family: 'General Sans';
                font-weight: 400;
                color: var(--primaryTextColor);
            }
        }
    }

    &__subContainer {
        box-sizing: border-box;
        width: 100%;
        height: calc(590px * var(--heightmultiplier));
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        //border: 1px solid black;

        &__listDown {
            box-sizing: border-box;
            height: fit-content;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            overflow-y: scroll;
        }


    }
}

.ListDownItems-container {
    height: calc(80px * var(--heightmultiplier));
    width: calc(1400px * var(--widthmultiplier));
    // background-color: var(--primaryColor);
    border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
    padding: calc(0px * var(--heightmultiplier)) calc(50px * var(--widthmultiplier)) calc(0px * var(--heightmultiplier)) calc(40px * var(--widthmultiplier));
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: calc(28px * var(--heightmultiplier));

    &__Sr-No {
        // height: calc(80px * var(--heightmultiplier));
        width: calc(100px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        //line-height: calc(27px * var(--heightmultiplier));
        // border: 1px solid black;
        // margin: calc(-10px * var(--heightmultiplier));

        color: var(--primaryTextColor);

        &__cnfrBtn {
            width: 180%;
            // height: 200%;
            color: var(--primaryTextColor);
            font-size: calc(20px * var(--heightmultiplier));
            //font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 10px;
            margin: 0px 10px;
            margin-top: 15px;
            border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            background-color: rgba($color: #6CCC54, $alpha: 0.2);
            border-color: #6CCC54;

            &:hover {
                background-color: rgba($color: #6CCC54, $alpha: 1);
                color: black;
            }
        }

        &__viewFlightButton {
            width: 180%;
            // height: 200%;
            color: var(--primaryTextColor);
            font-size: calc(20px * var(--heightmultiplier));
            //font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2px 10px;
            margin: 0px 10px;
            margin-top: 5px;
            border-radius: calc(5px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            background-color: rgba($color: #4285F4, $alpha: 0.2);
            border-color: #4285F4;

            &:hover {
                background-color: rgba($color: #4285F4, $alpha: 1);
                color: black;
            }
        }
    }

    &__name {
        // height: calc(80px * var(--heightmultiplier));
        width: calc(350px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        // border: 1px solid black;
        color: var(--primaryTextColor) // opacity: 0.4;
    }

    &__unit-price {
        // height: calc(80px * var(--heightmultiplier));
        width: calc(90px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        // border: 1px solid black;

        color: var(--primaryTextColor)
    }

    &__weight {
        // height: calc(80px * var(--heightmultiplier));
        width: calc(120px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        //line-height: calc(27px * var(--heightmultiplier));
        text-align: center;
        //border: 1px solid black;
        color: var(--primaryTextColor) // opacity: 0.4;
    }

    &__Volumetricweight {
        // height: calc(80px * var(--heightmultiplier));
        width: calc(190px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        // line-height: calc(27px * var(--heightmultiplier));
        text-align: center;
        //border: 1px solid black;
        color: var(--primaryTextColor) // opacity: 0.4;
    }

    &__quantity {
        // height: calc(80px * var(--heightmultiplier));
        width: calc(120px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        //line-height: calc(27px * var(--heightmultiplier));
        display: flex;
        align-items: center;
        justify-content: center;
        // border: 1px solid black;
        color: var(--primaryTextColor) // opacity: 0.4;
    }

    &__totalWeight {
        // height: calc(80px * var(--heightmultiplier));
        width: calc(135px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        //line-height: calc(27px * var(--heightmultiplier));
        text-align: center;

        color: var(--primaryTextColor) // opacity: 0.4;
    }

    &__total-price {
        width: calc(100px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        //line-height: calc(27px * var(--heightmultiplier));
        text-align: center;

        color: var(--primaryTextColor)
    }
}