.CustomNotification-modalBackground {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(6, 6, 6, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
  /* border: 1px solid black; */
}


.CustomNotification-modalContainer {
  width: 36%;
  height: 33.33%;

  border-radius: 0.75em;

  background-color: var(--primaryColor);
  box-sizing: border-box;
  box-shadow: #FD6025 0px 0px 15px;
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  /* box-shadow: #FD6125 0px 50px 100px -20px, #FD6125 0px 30px 60px -30px, #FD6125 0px -2px 6px 0px inset; */
 
  /* border: 5px solid #FC6025; */
  /* padding-top: calc(50px);
  padding-bottom: calc(50px); */
}


.custom-notify-box-img {
  /* width: calc(45px * var(--widthmultiplier)); */
  height: 29.17%;
  /* border: 1px solid green; */
  /* width: ; */
}

.custom-notify-box-msg {
  width: 90%;
  height: 33.33%;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 2em;
  text-align: center;
  color: var(--primaryTextColor);
  opacity: 0.8;
  margin-left: 10px;
  margin-right: 10px;
  

}

.custom-notify-box-button-container {
  display: flex;
  height: fit-content;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}
.custom-notify-box-name {
  height: fit-content;
  width: fit-content;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 2.5em;

  color: #1F2633;

}
#cancelBtn {
  width: 25.43%;
  height: fit-content;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-size: 2em;
  color: #FC6025;
  background-color: rgba(253,97,37, 0.1);
  border-radius: 5px;
  z-index: 12;
  display: flex;
  padding: 0px 10px;
  align-items: center;
  justify-content: center;
  /* border: 2px solid #FC6025; */
}

#cancelBtn:hover {
  cursor: pointer;
  /* color: white; */
  font-weight: 600;
  background-color: #FC6025;
  color: white;
}

#OkayBtn {
  
  box-sizing: border-box;
  width: 25.43%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 2em;
  background-color: rgba(253,97,37, 1);
  border-radius: 5px;
  color: white;

}

#OkayBtn:hover {
  cursor: pointer;
  background-color: #FC6025;
}