@import "../variables";

::-webkit-scrollbar {
  width: calc(8px * var(--widthmultiplier));
  border-radius: calc(4px * var(--widthmultiplier));
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba($color: #fd6125, $alpha: 0.2);
  border-radius: calc(4px * var(--widthmultiplier));
}

/* Handle */
::-webkit-scrollbar-thumb {
  // background: #cececf;
  background: #fd6125;
  border-radius: calc(4px * var(--widthmultiplier));
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  // background: #b30000;
  cursor: pointer;
}

.MyProfileDashBoard {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-between;
  //border: 1px solid red;
}

.MyProfileDashBoard-conatiner {
  //border: 1px solid black;
  width: 100%;
  height: 8%;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-right-radius: calc(16px * var(--heightmultiplier));

  &__title {
    height: 100%;
    //width: 70%;
    box-sizing: border-box;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: calc(40px * var(--heightmultiplier));
    // line-height: calc(54px * var(--heightmultiplier));
    color: var(--primaryTextColor);
    background-color: var(--secondaryColor);
    padding: calc(0px * var(--heightmultiplier)) calc(20px * var(--widthmultiplier));
    //border: 1px solid black;
    // margin-bottom: calc(48px * var(--heightmultiplier));
  }
}

.MyProfileDashBoard-SubConatiner {
  width: 98%;
  height: 90.5%;
  //border: 1px solid red;
  border-radius: calc(10px * var(--heightmultiplier));
  background-color: var(--primaryColor);
  padding: calc(15px* var(--heightmultiplier)) calc(10px* var(--widthmultiplier));

  &__image {
    width: 25%;
    height: 21.7%;
    display: flex;
    text-align: center;
    align-self: center;
    justify-content: flex-start;

    //border: 1px solid red;
    &__proImg {
      box-sizing: border-box;
      height: 100%;
      width: 40%;
      box-sizing: border-box;
      border-radius: 50%;
      color: var(--primaryTextColor);
      display: flex;
      align-items: left;
      justify-content: center;
      //border: 1px solid red;
    }

    &__name {
      box-sizing: border-box;
      height: 100%;
      width: 60%;
      // min-width: 50px;
      font-size: calc(30px * var(--heightmultiplier));
      font-weight: 600;
      margin-left: 0.5em;
      color: var(--primaryTextColor);
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      //border: 1px solid red;
    }
  }
  &__detailsBlock{
    width: 100%;
    height: fit-content;
    //border: 1px solid red;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  

}