.productCardContainer{
    position:absolute;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
    background-color: var(--primaryColor);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: calc(30px * var(--heightmultiplier)) calc(30px * var(--widthmultiplier));
    border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
    z-index:2;
    //border: 1px solid red;
    &:hover{
        border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
        // top: calc(-20px * var(--heightmultiplier));
         box-shadow: 0px 0px 15px #FD6125;
         //background-color: #FD6125;
    }
    &:focus{
        border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
        // top: calc(-20px * var(--heightmultiplier));
         box-shadow: 0px 0px 10px #FD6125;
         //background-color: #FD6125;
    }
}