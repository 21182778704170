.ListDownItem-container {
    height: calc(80px * var(--heightmultiplier));
    width: calc(1400px * var(--widthmultiplier));
    background-color: var(--primaryColor);
    border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
    padding: calc(0px * var(--heightmultiplier)) calc(50px * var(--widthmultiplier));
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: calc(28px * var(--heightmultiplier));

    &__Sr-No {
        // height: calc(80px * var(--heightmultiplier));
        width: calc(48px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        /* identical to box height */
        // text-align: center;


        /* Colors / Black / 100% */

        color: rgba($color: var(--primaryColor), $alpha: 0.4) // opacity: 0.4;
    }

    &__name {
        height: calc(27px * var(--heightmultiplier));
        width: calc(400px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        /* identical to box height */
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        /* Colors / Black / 100% */

        color: var(--primaryTextColor) // opacity: 0.4;
    }

    &__unit-price {
        // height: calc(80px * var(--heightmultiplier));
        width: calc(100px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        // border: 1px solid black;
        text-align: center;

        color: var(--primaryTextColor)
    }

    &__weight {
        // height: calc(80px * var(--heightmultiplier));
        width: calc(120px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        text-align: center;

        color: var(--primaryTextColor) // opacity: 0.4;
    }

    &__Volumetricweight {
        // height: calc(80px * var(--heightmultiplier));
        width: calc(190px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        text-align: center;

        color: var(--primaryTextColor) // opacity: 0.4;
    }

    &__quantity {
        position: relative;
        height: 100%;
        width: calc(120px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: calc(0px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
        // border: 1px solid black;
        color: var(--primaryTextColor); // opacity: 0.4;
        box-sizing: border-box;

        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
            appearance: textfield;
        }
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            appearance: none;
            margin: 0;
        }
        &__availbleQtyErr{
            position: absolute;
            top: calc(3px * var(--heightmultiplier));
            // height: 20%;
            font-size: calc(12px * var(--heightmultiplier));
            line-height: calc(12px * var(--heightmultiplier));
            // border: 1px solid black;
            color: red;
        }  
        &__backorderQtyErr{
            position: absolute;
            bottom: calc(3px * var(--heightmultiplier));
            // height: 20%;
            font-size: calc(12px * var(--heightmultiplier));
            line-height: calc(12px * var(--heightmultiplier));
            // border: 1px solid black;
            color: red;
        }  
        &__center{
            width: 100%;
            height: 50%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            
            &__input {
                width: 40px;
                text-align: center;
                border: none;
                outline: none;
                color: var(--primaryTextColor);
                background-color: inherit;
            }
    
            &__minus-plus {
                height: calc(30px * var(--heightmultiplier));
                width: calc(30px * var(--heightmultiplier));
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: calc(8px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                background-color: rgba($color: #FD6125, $alpha: 0.1);
                stroke: #FD6125;
    
                &:hover {
                    background-color: rgba($color: #FD6125, $alpha: 1);
                    stroke: #FD6125;
                    cursor: pointer;
                }
            }
        }

    }

    &__totalWeight {
        // height: calc(80px * var(--heightmultiplier));
        width: calc(135px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        text-align: center;

        color: var(--primaryTextColor) // opacity: 0.4;
    }

    &__total-price {
        width: calc(100px * var(--widthmultiplier));
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(20px * var(--heightmultiplier));
        line-height: calc(27px * var(--heightmultiplier));
        text-align: center;

        color: var(--primaryTextColor)
    }

    &__delete-img{
        height: calc(30px * var(--heightmultiplier));
        width: calc(24px * var(--widthmultiplier));
        
        &:hover{
            cursor: pointer;
        }
    }
}