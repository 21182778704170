@import "../variables";

::-webkit-scrollbar {
    width: calc(8px * var(--widthmultiplier));
    border-radius: calc(4px * var(--widthmultiplier));
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e6e7e7;
    border-radius: calc(4px * var(--widthmultiplier));
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #cececf;
    border-radius: calc(4px * var(--widthmultiplier));
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    // background: #b30000; 
    cursor: pointer;
}

.InventoryShowSubContent-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    // border: 1px solid black;

    &__title {
        box-sizing: border-box;
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: calc(40px * var(--heightmultiplier));
        line-height: calc(54px * var(--heightmultiplier));
        color: var(--primaryTextColor);
        margin-bottom: calc(48px * var(--heightmultiplier));

    }

    &__subContainer {
        box-sizing: border-box;
        width: 100%;
        height: calc(730px * var(--heightmultiplier));
        display: flex;
        align-items: center;
        justify-content: space-between;

        // border: 1px solid black;

        &__products-view {
            box-sizing: border-box;
            height: 100%;
            width: calc(902px * var(--widthmultiplier));
            // border: 1px solid black;
            overflow-y: scroll;
            display: flex;
            align-items: flex-start;
            justify-content: space-around;
            flex-wrap: wrap;
            padding-top: calc(15px * var(--heightmultiplier));
            padding-bottom: calc(15px * var(--heightmultiplier));
            padding-right: calc(10px * var(--widthmultiplier));
            padding-left: calc(10px * var(--widthmultiplier));

            &__test {
                box-sizing: border-box;
                height: 200%;
                width: 100%;
                background-color: var(--primaryColor);
            }
        }

        &__product-description {
            position: relative;
            box-sizing: border-box;
            height: 100%;
            background-color: var(--primaryColor);
            ;
            width: calc(553px * var(--widthmultiplier));
            // border: 1px solid black;
            padding: calc(41px * var(--heightmultiplier)) calc(41px * var(--widthmultiplier));
            border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;


            &__title {
                font-family: 'General Sans';
                font-style: normal;
                font-weight: 500;
                font-size: calc(30px * var(--heightmultiplier));
                line-height: calc(30px * var(--heightmultiplier));
                margin-top: calc(9px * var(--heightmultiplier));
                margin-bottom: calc(25px * var(--heightmultiplier));
            }

            &__descrip {
                font-family: 'General Sans';
                font-style: normal;
                font-weight: 400;
                font-size: calc(18px * var(--heightmultiplier));
                line-height: calc(25px * var(--heightmultiplier));
                // color: var(--secondaryTextColor);
                color: rgba($color: var(--primaryTextColor), $alpha: 0.4);
                text-align: justify;
            }

            &__addToCart {
                // position: absolute;
                // bottom: calc(18px * var(--heightmultiplier));
                // left: 0;
                height: fit-content;
                width: 100%;
                font-family: 'General Sans';
                font-style: normal;
                font-weight: 400;
                font-size: calc(18px * var(--heightmultiplier));
                line-height: calc(25px * var(--heightmultiplier));
                // color: var(--secondaryTextColor);
                color: rgba($color: var(--primaryTextColor), $alpha: 0.4);
                display: flex;
                align-items: center;
                justify-content: space-around;
                // padding: calc(0px * var(--heightmultiplier)) calc(41px * var(--widthmultiplier));
                margin: calc(16px * var(--heightmultiplier)) calc(0px * var(--widthmultiplier));
                
                // border: 1px solid black;

                &__input{
                    
                    padding: calc(5px * var(--heightmultiplier)) calc(10px * var(--widthmultiplier));
                    border: 2px solid rgba($color: #FD6125, $alpha: 0.4);
                    outline: none;
                    border-radius: calc(8px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
                    width: calc(150px * var(--widthmultiplier));
                }
            }

        }
    }
}
